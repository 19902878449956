<template>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
        <van-field
            v-model="apply.customer_name"
            name="客戶名稱"
            label="客戶名稱"
            required
            placeholder="客戶名稱"
            :rules="[{ required: true, message: '請填寫客戶名稱' }]"
        />
        <van-field name="radio" label="性別">
            <template #input>
                <van-radio-group v-model="apply.customer_sex" direction="horizontal">
                <van-radio name="0">女性</van-radio>
                <van-radio name="1">男性</van-radio>
                <van-radio name="2">無</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field
            v-model="apply.customer_mobile"
            type="tel"
            name="聯絡電話"
            label="聯絡電話"
            required
            placeholder="聯絡電話"
            :rules="[{ required: true, message: '請填寫聯絡電話' }]"
        />
        <van-field
            v-model="apply.customer_address"
            name="客戶地址"
            label="客戶地址"
            placeholder="請填寫客戶地址"
        />
        <van-field
            v-model="apply.people"
            type="number"
            name="旅遊人數"
            label="旅遊人數"
            placeholder="請填寫旅遊人數"
        />
        <van-field
            v-model="apply.start_date"
            is-link
            readonly
            :columns-type="columnsType"
            name="startTime"
            label="租賃開始日期"
            placeholder="請選擇租賃開始日期"
            @click="showDatePicker = true"
        />
        <van-popup v-model:show="showDatePicker" position="bottom">
            <van-date-picker @confirm="onDateConfirm" @cancel="showDatePicker = false" :min-date="minDate" :max-date="maxDate" confirm-button-text="確認"/>
        </van-popup>
        <van-field
            v-model="apply.start_time"
            is-link
            readonly
            name="startTime"
            label="開始時間"
            placeholder="請選擇租賃開始時間"
            @click="showTimePicker = true"
        />
        <van-popup v-model:show="showTimePicker" position="bottom">
            <van-time-picker @confirm="onTimeConfirm" @cancel="showTimePicker = false" confirm-button-text="確認" :filter="filter" />
        </van-popup>
        <van-field
            v-model="apply.end_date"
            is-link
            readonly
            :columns-type="columnsType"
            name="endDate"
            label="租賃結束日期"
            placeholder="請選擇租賃結束日期"
            @click="showDateEndPicker = true"
        />
        <van-popup v-model:show="showDateEndPicker" position="bottom">
            <van-date-picker @confirm="onDateEndConfirm" @cancel="showDateEndPicker = false"  :min-date="minDate" :max-date="maxDate" confirm-button-text="確認" />
        </van-popup>
        <van-field
            v-model="apply.end_time"
            is-link
            readonly
            name="endTime"
            label="結束時間"
            placeholder="請選擇租賃結束時間"
            @click="showTimeEndPicker = true"
        />
        <van-popup v-model:show="showTimeEndPicker" position="bottom">
            <van-time-picker @confirm="onTimeEndConfirm" @cancel="showTimeEndPicker = false" confirm-button-text="確認" :filter="filter" />
        </van-popup>
        <van-field
            v-model="apply.route"
            name="行駛路線"
            label="行駛路線"
            placeholder="請填寫行駛路線"
        />
        <van-field
            v-model="apply.rental_cost"
            name="租車費用"
            type="number"
            label="租車費用"
            placeholder="租車費用"
            :rules="[{ message: '請填寫租車費用' }]"
        />
        <van-field
            v-model="apply.odometer"
            name="里程數"
            type="number"
            label="里程數"
            placeholder="請填寫車輛里程數"
        />
        <van-field
            v-model="apply.flight_no"
            name="flight_no"
            label="航班編號"
            placeholder="請填寫航班編號"
        />
    </van-cell-group>
    <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
        申 請
        </van-button>
    </div>
    </van-form>
</template>

<script setup>
import{ ref, reactive, onMounted } from 'vue';
import liff from '@line/liff';
import axios from 'axios'
import { showToast, showLoadingToast, closeToast } from 'vant';

    const today = new Date();
    const currentDate = ref(formatDateToArray(today).join('-'));
    const currentTime = ref(['12', '00'].join(':'));
    const currentEndDate = ref(formatDateToArray(today).join('-'));
    const currentEndTime = ref(['12', '00'].join(':'));
    let apply = reactive({
        'customer_name': '',
        'customer_sex': '0',
        'customer_mobile': '',
        'customer_address': '',
        'people': '',
        'start_date': currentDate,
        'start_time': currentTime,
        'end_date': currentEndDate,
        'end_time': currentEndTime,
        'route': '',
        'rental_cost': '',
        'odometer': '',
        'line_id': '',
        'nickname': '',
        'flight_no': ''
    });
    const showDatePicker = ref(false);
    const showTimePicker = ref(false);
    const showDateEndPicker = ref(false);
    const showTimeEndPicker = ref(false);
    const onDateConfirm = ({ selectedValues }) => {
        // console.log(selectedValues)
        apply.start_date = selectedValues.join('-');
        apply.end_date = selectedValues.join('-');
        showDatePicker.value = false;
    };
    const onTimeConfirm = ({ selectedValues }) => {
        apply.start_time = selectedValues.join(':');
        apply.end_time = selectedValues.join(':');
        showTimePicker.value = false;
    };
    const onDateEndConfirm = ({ selectedValues }) => {
        apply.end_date = selectedValues.join('-');
        showDateEndPicker.value = false;
    };
    const onTimeEndConfirm = ({ selectedValues }) => {
        apply.end_time = selectedValues.join(':');
        showTimeEndPicker.value = false;
    };
    const filter = (type, options) => {
      if (type === 'minute') {
        return options.filter((option) => Number(option.value) % 10 === 0);
      }
      return options;
    };
    const columnsType = ['year', 'month', 'day'];
    apply.start_date =  currentDate
    apply.start_time =  currentTime
    apply.end_date =  currentEndDate
    apply.end_time =  currentEndTime
    let minDate = new Date(formatDate(today))
    let maxDate = new Date(2028, 5, 1)
    const onSubmit = ( async () => {
        // console.log('apply');
        var title = apply.customer_name + (apply.customer_sex == '0' ? " 小姐/女士" : " 先生") +" 的派車單"
        var text = "客戶電話: " + apply.customer_mobile
        const url = "https://cars.chihlistudio.com/api/dispatch"
        let payload = {
            customer_name: apply.customer_name,
            customer_sex: apply.customer_sex,
            customer_mobile: apply.customer_mobile,
            customer_address: apply.customer_address,
            people: apply.people,
            line_id: apply.line_id,
            nickname: apply.nickname,
            start_date: apply.start_date,
            start_time: apply.start_time,
            end_date: apply.end_date,
            end_time: apply.end_time,
            rental_cost: apply.rental_cost,
            odometer: apply.odometer,
            route: apply.route,
            flight_no: apply.flight_no
        }
        // console.log('payload ', payload);
        showLoadingToast({
            type: "loading",
            message: "加載中...",
            forbidClick: true,
            overlay: true,
            duration: 0,
            loadingType: "spinner"
        });
        let rs = await axios.post(url, payload);
        // console.log('rs ', rs.data);
        if(rs.data.success){
            const imageUrl = "https://cars.chihlistudio.com/images/" + rs.data.data.dispatch_image_url
            console.log(imageUrl);
            liff.sendMessages([
                {
                    "type": "template",
                    "altText": "這是派車單",
                    "template": {
                        "type": "carousel",
                        "columns": [{
                            "title": title,
                            "text": text,
                            "actions": [
                                {
                                    "type": "uri",
                                    "label": "開啟派車單",
                                    "uri": imageUrl
                                }
                            ],
                            // "thumbnailImageUrl": "https://ssl.pstatic.net/linefriends/wp-content/uploads/2017/03/char_choco_name.png"
                        }],
                        "imageAspectRatio": "rectangle"
                    }
                }
                ]).then(() => {
                    closeToast();
                    showToast(rs.data.message);
                    liff.closeWindow();
                })
                .catch((error) => {
                    window.alert(error)
                    closeToast();
                    showToast(rs.data.message);
                    liff.closeWindow();
                });
            }else{
                closeToast();
                showToast(rs.data.message);
                liff.closeWindow();
            }
    })
    function formatDate (date) {
        const year = String(date.getFullYear());
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    function formatDateToArray (date) {
        const year = String(date.getFullYear());
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return [year, month, day];
    }
    onMounted( async () => {
        liff.init({
            liffId: '2005566839-vwR8agRD', // Use own liffId
            // withLoginOnExternalBrowser: true,
        }).then(function() {
            console.log('LIFF init');
            if (!liff.isLoggedIn()) {
                liff.login({ 
                    // 登入後要轉址到哪個網站
                    redirectUri:"https://dev.chihlistudio.com/apply" 
                });
            }else{
                liff.getProfile()
                .then(profile => {
                    const name = profile.displayName
                    apply.nickname = name
                    apply.line_id = profile.userId
                    // console.log('name', name);
                })
                .catch((err) => {
                    console.log('error', err);
                });
            }
        // 開始使用liff其他功能
        }).catch(function(error) {
            console.log(error);
        });
    })
    
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>
