<template>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
        <van-field
          v-model="person.username"
          name="姓名"
          required
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '請填寫姓名' }]"
        />
        <van-field name="radio" required label="性別">
            <template #input>
                <van-radio-group v-model="person.sex" direction="horizontal">
                <van-radio name="0">女性</van-radio>
                <van-radio name="1">男性</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field
          v-model="person.mobile"
          type="tel"
          name="手機"
          required
          label="手機"
          placeholder="手機"
          :rules="[{ required: true, message: '請填寫手機' }]"
        />
        <van-field
          v-model="person.address"
          name="地址"
          label="地址"
          placeholder="地址"
        />
        <van-field
          v-model="person.person_id"
          name="職業駕照號碼"
          label="職業駕照"
          required
          placeholder="請填寫職業駕照(身分證號)"
        />
        <van-field
          v-model="person.car.car_license"
          name="牌照號碼"
          required
          label="牌照號碼"
          placeholder="牌照號碼"
          :rules="[{ required: true, message: '請填寫牌照號碼' }]"
        />
        <van-field
          v-model="person.car.car_type"
          name="廠牌型式"
          label="廠牌型式"
          placeholder="廠牌型式"
        />
        <van-field
            v-model="person.dealer_name"
            required
            name="dealer_name"
            label="車行名稱"
            placeholder="請輸入車行名稱"
        />
        <van-field
            v-model="person.join_code"
            required
            name="join_code"
            label="駕駛邀請碼"
            placeholder="請輸入駕駛邀請碼，如不知道，請與車行詢問"
        />
        <!-- <van-popup v-model:show="showVendorPicker" position="bottom">
          <van-picker
            title="所屬車行"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showVendorPicker = false"
            @change="onChange"
            confirm-button-text="確認"
          />
        </van-popup> -->
        
    </van-cell-group>
    <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
        註 冊
        </van-button>
    </div>
    </van-form>
</template>

<script setup>
import{ reactive, onMounted } from 'vue';
import liff from '@line/liff';
import axios from 'axios'
import { showToast, showLoadingToast, closeToast } from 'vant';

let person = reactive({
        'line_id': '',
        'name': '',
        'nickname': '',
        'sex': '0',
        'mobile': '',
        'address': '',
        'join_code': '',
        'person_id': '',
        'dealer_name': '',
        'car': {
          'car_license': '',
          'car_type': '',
        },
        
    });
const onSubmit = ( async () => {
    // console.log('submit', values);
    const url = "https://cars.chihlistudio.com/api/driver"
    const payload = {
        name: person.username,
        nickname: person.nickname,
        mobile: person.mobile,
        address: person.address,
        sex: person.sex,
        line_id: person.line_id,
        join_code: person.join_code,
        person_id: person.person_id,
        dealer_name: person.dealer_name,
        car: {
            car_license: person.car.car_license,
            // car_engine_no: person.car.car_engine_no,
            car_type: person.car.car_type
        }
    }
    console.log('submit payload ', payload);
    showLoadingToast({
        type: "loading",
        message: "加載中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
    });

    let rs = await axios.post(url, payload);
    console.log('message ', rs.data.message);
    if(rs.data.success){
        liff.sendMessages([
            {
                "type": "text",
                "text": "註冊成功!等候車行審核通知!"
            }
        ]).then(() => {
          closeToast();
          liff.closeWindow();
        });
        showToast(rs.data.message);

    }else{
        liff.sendMessages([
            {
                "type": "text",
                "text": rs.data
            }
        ]).then(() => {
          closeToast();
          liff.closeWindow();
          showToast(rs.data);
        });
    }
})
onMounted( async () => {
  liff.init({
      liffId: '2005566839-jdm6vymB', // Use own liffId
      // withLoginOnExternalBrowser: true,
  }).then(function() {
      // console.log('LIFF init');
      if (!liff.isLoggedIn()) {
          liff.login({ 
              // 登入後要轉址到哪個網站
              redirectUri:"https://dev.chihlistudio.com" 
          });
      }else{
          liff.getProfile()
          .then(profile => {
              const name = profile.displayName
              person.nickname = name
              person.line_id = profile.userId
          })
          .catch((err) => {
              console.log('error', err);
          });
          
          // 登出
          // liff.logout({
          //     redirectUri:"https://4215-123-252-20-145.ngrok-free.app/"
          // });
      }

  
  // 開始使用liff其他功能
  
  }).catch(function(error) {
      console.log(error);
  });
  
})
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
