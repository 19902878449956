<template>
    <div>
        <van-card
            v-for="(item) in dispatch" :key=item.id
            currency="$"
            :price="item.rental_cost"
            thumb="https://cars.chihlistudio.com/images/app/dispatch_history_image.png"
            
            >
            <template #title>
                <span style="font-size: 14px; font-weight: 600;">
                    {{ item.customer_name }}
                </span>
            </template>
            <template #desc>
                <!-- <div>派車單號: {{ item.dispatch_id }}</div> -->
                <div>連絡電話: <span style="color: blue;">{{ item.customer_mobile }}</span></div>
                <div>行駛路線:</div>
            </template>
            <template #num>
                <span style="font-size: 14px; font-weight: 600;">
                    <span style="margin-right: 1rem;">{{ item.created_at }}</span>
                    <van-button v-show="item.signature_file==null" size="mini" type="success" @click="showSignature(item)">簽 名</van-button>
                    <van-button v-show="item.image_path!=null" size="mini" type="primary" @click="showDispatch(item.image_path)">顯 示</van-button>
                </span>
            </template>
        </van-card>
        <van-popup v-model:show="showSignaturePopUp" closeable position="bottom" :closeOnClickOverlay="false">
            <van-cell-group inset>
                <van-field
                    v-model="select_customer_name"
                    name="select_customer_name"
                    label="客戶名稱"
                    required
                    placeholder="請填寫客戶名稱"
                />
                <van-field
                    v-model="select_start_date"
                    name="select_start_date"
                    label="日期"
                    required
                    placeholder="請填寫日期"
                />
                <van-field
                    v-model="select_rental_cost"
                    name="select_rental_cost"
                    label="金額"
                    required
                    placeholder="請填寫金額"
                />
            </van-cell-group>
            <van-signature @submit="onSubmit" @clear="onClear" confirmButtonText="確 認" clearButtonText="清除" />
        </van-popup>
        <van-popup v-model:show="showDispatchPopUp" style="padding-top: 5px; text-align: center;">
            <img height="100%" width="100%" :src="image_path" alt="">
        </van-popup>
    </div>
</template>
<script setup>
import{ ref, reactive, onMounted } from 'vue';
import axios from 'axios'
import liff from '@line/liff';
import { showToast, showLoadingToast, closeToast } from 'vant';

let dispatch = ref([]);
let image_path = ref();
const signature_file = ref('');
let showDispatchPopUp = ref(false);
const showSignaturePopUp = ref(false);
const select_dispatch_id = ref();
const select_customer_name = ref();
const select_start_date = ref();
const select_rental_cost = ref();

// console.log('A0 ', mydata);
let person = reactive({
    'line_id': '',
    'nickname': ''
});
const onClear = () => {
    
}
const onSubmit = (data) => {
    signature_file.value = data.image
    // console.log('signature_file ', signature_file.value);
    if(signature_file.value == '') {
        showToast('請先簽名!');
        return false;
    }
    showLoadingToast({
        type: "loading",
        message: "載入中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
    });
    // axios.post('https://e0de0a6e28da.ngrok.app/api/uploadsignature', {
    axios.post('https://cars.chihlistudio.com/api/uploadsignature', {
        'dispatch_id': select_dispatch_id.value,
        'line_id': person.line_id,
        'imgBase64': signature_file.value
    }).then((rs) => {
        console.log('rs ', rs);
        if(rs.data.success){
            showToast('更新成功!');
            window.location.reload()
        }
    })
    closeToast();
    showSignaturePopUp.value = false
}
const showSignature = (item) => {
    console.log('showSignature dispatch_id ', item);
    select_dispatch_id.value = item.dispatch_id
    select_customer_name.value = item.customer_name
    select_rental_cost.value = item.rental_cost
    select_start_date.value = item.start_time
    showSignaturePopUp.value = true
}
const showDispatch = (data) => {
    console.log('dispatch_id ', data);
    // image_path.value = 'https://e0de0a6e28da.ngrok.app/' + data
    image_path.value = 'https://cars.chihlistudio.com/' + data
    console.log('image_path ', image_path);
    showDispatchPopUp.value = true
}
onMounted( async () => {
    liff.init({
      liffId: '2005566839-MDEaQPEg', // Use own liffId
      // withLoginOnExternalBrowser: true,
    }).then(function() {
        if (!liff.isLoggedIn()) {
            liff.login({ 
                // 登入後要轉址到哪個網站
                redirectUri:"https://dev.chihlistudio.com/historyv2" 
            });
        }else{
            liff.getProfile()
            .then(profile => {
                // console.log('profile ', profile);
                const name = profile.displayName
                person.nickname = name
                person.line_id = profile.userId
                showLoadingToast({
                    type: "loading",
                    message: "載入中...",
                    forbidClick: true,
                    overlay: true,
                    duration: 0,
                    loadingType: "spinner"
                });
                const payload = reactive({
                    "line_id": person.line_id,
                })
                axios.post('https://cars.chihlistudio.com/api/historyv2', payload).then((rs) => {
                // axios.post('https://e0de0a6e28da.ngrok.app/api/historyv2', payload).then((rs) => {
                    dispatch.value = rs.data.data
                    closeToast();
                })
            })
            .catch((err) => {
                console.log('error', err);
            });
        }
    
    }).catch(function(error) {
        console.log(error);
    });
    
})
</script>
<style scoped>

</style>