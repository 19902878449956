<template>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
        <van-field
            v-model="apply.customer_name"
            name="customer_name"
            label="公司名稱"
            required
            placeholder="公司名稱"
            :rules="[{ required: true, message: '請填寫公司名稱' }]"
        />
        <van-field
            v-model="apply.customer_mobile"
            type="tel"
            name="customer_mobile"
            label="聯絡電話"
            placeholder="聯絡電話"
        />
        <van-field
            v-model="apply.customer_address"
            name="customer_address"
            label="部門(搭車人員)"
            placeholder="請填寫部門"
        />
        <!-- <van-field
            v-model="apply.people"
            type="number"
            name="旅遊人數"
            label="旅遊人數"
            placeholder="請填寫旅遊人數"
        /> -->
        <van-field
            v-model="apply.start_date"
            is-link
            readonly
            required
            :columns-type="columnsType"
            name="startTime"
            label="開始日期"
            placeholder="請選擇開始日期"
            @click="showDatePicker = true"
        />
        <van-popup v-model:show="showDatePicker" position="bottom">
            <van-date-picker @confirm="onDateConfirm" @cancel="showDatePicker = false" :min-date="minDate" :max-date="maxDate" confirm-button-text="確認"/>
        </van-popup>
        <!-- <van-field
            v-model="apply.start_time"
            is-link
            readonly
            name="startTime"
            label="開始時間"
            placeholder="請選擇租賃開始時間"
            @click="showTimePicker = true"
        />
        <van-popup v-model:show="showTimePicker" position="bottom">
            <van-time-picker @confirm="onTimeConfirm" @cancel="showTimePicker = false" confirm-button-text="確認" :filter="filter" />
        </van-popup> -->
        <!-- <van-field
            v-model="apply.end_date"
            is-link
            readonly
            :columns-type="columnsType"
            name="endDate"
            label="租賃結束日期"
            placeholder="請選擇租賃結束日期"
            @click="showDateEndPicker = true"
        />
        <van-popup v-model:show="showDateEndPicker" position="bottom">
            <van-date-picker @confirm="onDateEndConfirm" @cancel="showDateEndPicker = false"  :min-date="minDate" :max-date="maxDate" confirm-button-text="確認" />
        </van-popup> -->
        <!-- <van-field
            v-model="apply.end_time"
            is-link
            readonly
            name="endTime"
            label="結束時間"
            placeholder="請選擇租賃結束時間"
            @click="showTimeEndPicker = true"
        />
        <van-popup v-model:show="showTimeEndPicker" position="bottom">
            <van-time-picker @confirm="onTimeEndConfirm" @cancel="showTimeEndPicker = false" confirm-button-text="確認" :filter="filter" />
        </van-popup> -->
        <van-field name="checkboxGroup" required label="開始時間">
            <template #input>
                <van-radio-group v-model="apply.start_time" direction="horizontal">
                    <van-radio name="0600">日 06:00~23:00</van-radio>
                    <van-radio style="margin-top: 0.3rem;" name="2300">夜 23:00~06:00</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field
            v-model="apply.route_start"
            name="route_start"
            required
            label="路線開始"
            placeholder="請填寫行駛開始路線"
        />
        <van-field
            v-model="apply.route_end"
            name="route_end"
            required
            label="路線結束"
            placeholder="請填寫路線結束地點"
        />
        <van-field
            v-model="apply.rental_cost"
            name="rental_cost"
            type="number"
            required
            label="車資"
            placeholder="車資費用"
        />
        <!-- <van-field
            v-model="apply.odometer"
            name="odometer"
            type="number"
            label="里程數"
            placeholder="請填寫車輛里程數"
        /> -->
        <p>簽名</p>
        <van-signature @submit="onSave" @clear="onClear" confirmButtonText="確認與送出" clearButtonText="清除" />
        <!-- <van-image v-if="image" :src="image" /> -->
    </van-cell-group>
    <!-- <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
        申 請
        </van-button>
    </div> -->
    </van-form>
</template>

<script setup>
import{ ref, reactive, onMounted } from 'vue';
import liff from '@line/liff';
import axios from 'axios'
import { showToast, showLoadingToast, closeToast } from 'vant';

    const image = ref('');
    const today = new Date();
    const showDatePicker = ref(false);

    let apply = reactive({
        'customer_name': '',
        'customer_mobile': '',
        'customer_address': '',
        'start_date': '',
        'start_time': '0600',
        'people': '',
        'route_start': '',
        'route_end': '',
        'rental_cost': '',
        'odometer': '',
        'line_id': '',
        'nickname': ''
    });
    let minDate = new Date(formatDate(today))
    let maxDate = new Date(2028, 5, 1)
    const columnsType = ['year', 'month', 'day'];
    const onDateConfirm = ({ selectedValues }) => {
        console.log(selectedValues)
        apply.start_date = selectedValues.join('-');
        apply.end_date = selectedValues.join('-');
        showDatePicker.value = false;
    };
    const onClear = () => showToast('clear');
    const onSave = (data) => {
        console.log('save', data);
        image.value = data.image;
        const customerData = {
            'customer_name': apply.customer_name,
            'customer_mobile': apply.customer_mobile,
            'customer_address': apply.customer_address,
        }
        localStorage.setItem("user_data", JSON.stringify(customerData));
        showLoadingToast({
            type: "loading",
            message: "加載中...",
            forbidClick: true,
            overlay: true,
            duration: 0,
            loadingType: "spinner"
        });
        let formData = new FormData();
        formData.append('customer_name', apply.customer_name);
        formData.append('customer_mobile', apply.customer_mobile);
        formData.append('customer_department', apply.customer_address);
        formData.append('start_date', apply.start_date);
        formData.append('start_time', apply.start_time);
        formData.append('route_start', apply.route_start);
        formData.append('route_end', apply.route_end);
        formData.append('rental_cost', apply.rental_cost);
        formData.append('line_user_id', apply.line_id);
        formData.append('imgBase64', image.value);
        axios.post('https://cars.chihlistudio.com/api/dispatchv2', formData, {
        // axios.post('https://2caea9d13fdf.ngrok.app/api/dispatchv2', formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            console.log('上傳成功', response.data);
            if(response.data.success) {
                const title = response.data.data.customer_name + " 的派車單"
                const mobileString = (response.data.data.customer_mobile == null) ? "--" : response.data.data.customer_mobile;
                const imageUrl = "https://cars.chihlistudio.com/" + response.data.data.image_path
                liff.sendMessages([
                {
                    "type": "template",
                    "altText": "這是派車單",
                    "template": {
                        "type": "carousel",
                        "columns": [{
                            "title": title,
                            "text": mobileString,
                            "actions": [
                                {
                                    "type": "uri",
                                    "label": "開啟派車單",
                                    "uri": imageUrl
                                }
                            ],
                        }],
                        "imageAspectRatio": "rectangle"
                    }
                }
                ]).then(() => {
                    closeToast();
                    showToast(response.data.message);
                    liff.closeWindow();
                }).catch((error) => {
                    closeToast();
                    showToast(error);
                    liff.closeWindow();
                })
                showToast(response.data.message);
            }else{
                closeToast();
                showToast(response.data.message);
            }
        })
        .catch(error => {
            closeToast();
            showToast('新增錯誤，請與系統管理員聯繫!');
            console.error('上传失败', error);
        });
    }
    function formatDate (date) {
        const year = String(date.getFullYear());
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    onMounted( async () => {
        liff.init({
            liffId: '2005566839-6zRPDpRd', // Use own liffId
            // withLoginOnExternalBrowser: true,
        }).then(function() {
            console.log('LIFF init');
            if (!liff.isLoggedIn()) {
                liff.login({ 
                    // 登入後要轉址到哪個網站
                    redirectUri:"https://dev.chihlistudio.com/applyv2" 
                });
            }else{
                liff.getProfile()
                .then(profile => {
                    const name = profile.displayName
                    apply.nickname = name
                    apply.line_id = profile.userId
                    const customerData = localStorage.getItem("user_data");
                    if(customerData) {
                        const customer = JSON.parse(customerData);
                        apply.customer_name = customer.customer_name
                        apply.customer_mobile = customer.customer_mobile
                        apply.customer_address = customer.customer_address
                    }
                })
                .catch((err) => {
                    console.log('error', err);
                });
            }
        // 開始使用liff其他功能
        }).catch(function(error) {
            console.log(error);
        });
    })
    
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>
