<template>
  <van-nav-bar :title="title" >
    <!-- <template #right>
      <span>Lang </span>
      <van-popover v-model="locale" theme="dark" :actions="option1" @select="onSelect" placement="bottom-end">
        <template #reference>
          <van-icon name="more" size="28" />
        </template>
      </van-popover>
    </template> -->
  </van-nav-bar>

  <!-- <RegisterForm /> -->
  <RouterView />
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from 'vue-router'
import { useI18n } from "vue-i18n";
// import { showToast } from 'vant';

const route = useRoute()
const { t, locale } = useI18n();
const title = ref(t("messages.title"))
// const showPopover = ref(false);

// const option1 = [
//       { text: '繁體', value: 'zh-TW' },
//       { text: 'English', value: 'en-US' },
//       // { text: 'Japen', value: 2 },
//     ];
// const onSelect = (action) => {
//   showPopover.value = true
//   showToast(action.value)
//   locale.value = action.value
//   localStorage.setItem("locale", action.value);
// };
watch(locale, (newlocale)=>{
  title.value = route.meta.title
  console.log('new locale ', newlocale)
  localStorage.setItem("locale", newlocale);
})


const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class extends _ResizeObserver {
  constructor(callback) {
    super(debounce(callback, 100));
  }
}
</script>

<style scoped>

</style>
