<template>
    <div>
        <van-card
            v-for="(item) in dispatch" :key=item.id
            currency="$"
            :price="item.rental_cost"
            thumb="https://cars.chihlistudio.com/images/app/dispatch_history_image.png"
            
            >
            <template #title>
               <span style="font-size: 14px; font-weight: 600;">
                    {{ item.customer_name }}
                    {{ (item.customer_sex == '0' ? " 小姐/女士" : " 先生") }}
                </span>
            </template>
            <template #desc>
                <!-- <div>派車單號: {{ item.dispatch_id }}</div> -->
                <div>連絡電話: <span style="color: blue;">{{ item.customer_mobile }}</span></div>
                <div>行駛路線:</div>
            </template>
            <template #num>
                <span style="font-size: 14px; font-weight: 600;">
                    <span style="margin-right: 1rem;">{{ item.created_at }}</span>
                    <van-button size="mini" type="success" @click="showDispatch(item.dispatch_id)">顯示</van-button>
                </span>
            </template>
        </van-card>
        <van-popup v-model:show="showDispatchPopUp" style="padding-top: 5px; text-align: center;">
           <img height="100%" width="100%" :src="image_path" alt="">
        </van-popup>

    </div>
</template>
<script setup>
import{ ref, reactive, onMounted } from 'vue';
import axios from 'axios'
import liff from '@line/liff';
import { showLoadingToast, closeToast } from 'vant';


let dispatch = ref([]);
let image_path = ref();
let showDispatchPopUp = ref(false);

// console.log('A0 ', mydata);
let person = reactive({
    'line_id': '',
    'nickname': ''
});
const showDispatch = (dispatch_id) => {
    console.log('dispatch_id ', dispatch_id);
    image_path.value = 'https://cars.chihlistudio.com/images/' + dispatch_id + '.jpg'
    console.log('image_path ', image_path);
    showDispatchPopUp.value = true
}
onMounted( async () => {
    liff.init({
      liffId: '2005566839-MwQYa2Qb', // Use own liffId
      // withLoginOnExternalBrowser: true,
    }).then(function() {
        // console.log('LIFF init');
        if (!liff.isLoggedIn()) {
            liff.login({ 
                // 登入後要轉址到哪個網站
                redirectUri:"https://dev.chihlistudio.com/history" 
            });
        }else{
            liff.getProfile()
            .then(profile => {
                // console.log('profile ', profile);
                const name = profile.displayName
                person.nickname = name
                person.line_id = profile.userId
                // console.log('name', name);
                showLoadingToast({
                    type: "loading",
                    message: "載入中...",
                    forbidClick: true,
                    overlay: true,
                    duration: 0,
                    loadingType: "spinner"
                });
                const payload = reactive({
                    "line_id": person.line_id,
                })
                axios.post('https://cars.chihlistudio.com/api/history', payload).then((rs) => {
                    dispatch.value = rs.data.data
                    closeToast();
                })
            })
            .catch((err) => {
                console.log('error', err);
            });
            
            // 登出
            // liff.logout({
            //     redirectUri:"https://4215-123-252-20-145.ngrok-free.app/"
            // });
        }

    
    // 開始使用liff其他功能
    
    }).catch(function(error) {
        console.log(error);
    });
})
</script>
<style scoped>

</style>